<template>
  <div id="commentsInterface" class="container">
    <button
      class="btn"
      @click="showComments = true"
      v-if="!showComments"
    >
      <i class="fas fa-comments"></i> Comments ({{ comments.length }})
      <span
        class="text-danger"
        v-if="fieldData.requireComments"
      > *Required</span>
    </button>
    <button
      class="btn"
      @click="showComments = false"
      v-if="showComments"
    >
      <i class="fas fa-comments"></i> Comments
      <span
        class="text-danger"
        v-if="fieldData.requireComments"
      > *Required</span>
      &nbsp;<i class="fas fa-caret-up"></i>
    </button>
    <div v-if="showComments" class="mt-2">
      <!-- COMMENTS INPUT -->
      <div class="row pb-2">
        <div class="col-1">
          <i class="fas fa-comment comment-icon"></i>
        </div>
        <div class="col-10">
          <input
            v-model="comment"
            type="text"
            id="commentForm"
            class="form-control"
            placeholder="Write your comment here..."
          />
        </div>
        <div class="col-1">
          <button
            v-if="comment"
            @click="submitComment()"
            class="btn btn-success"
            style="float: left;"
          >
            <i class="fas fa-arrow-right"></i>
          </button>
          <button
            v-else
            class="btn btn-secondary"
            style="float: left;"
          >
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
      <!-- COMMENTS LIST -->
      <div class="row" v-if="comments.length >= 1">
        <ul>
          <li
            v-for="(commentItem, index) in comments"
            v-bind:key="index"
          >
          <span v-if="commentItem.userRoles">
            <span
              v-for="role in commentItem.userRoles"
              v-bind:key="role"
              class="badge rounded-pill text-bg-success me-1"
            >{{ role }}</span><br>
          </span>
          <small>{{ commentItem.userName }}</small><br>
          {{ commentItem.content }}<br/><small>{{ formatTimestampToUSDate(commentItem.createdOn.seconds) }}</small></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import fb from '../../firebaseConfig'
export default {
  data () {
    return {
      comment: '',
      comments: [],
      showComments: false
    }
  },
  props: ['fieldData', 'modelValue'],
  created () {
    this.comments = this.modelValue || []
  },
  methods: {
    formatTimestampToUSDate(timestampInSeconds) {
      if (timestampInSeconds) {
        const date = new Date(timestampInSeconds * 1000)
        const options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        }
        return date.toLocaleDateString('en-US', options)
      } else {
        return 'Now'
      }
    },
    async submitComment () {
      if (this.$store.state.submission.id != 'preview') {
        const commentObject = {
          content: this.comment,
          createdOn: new Date(),
          createdBy: this.$store.state.currentUser.uid,
          userName: this.$store.state.currentUser.email,
          userRoles: this.$store.state.roles
        }
        this.comments.unshift(commentObject)
        this.$emit('update:modelValue', this.comments)
        console.log(this.$store.state.submission)
        
        // Add a Revision
        // SAVE REVISION FROM CURRENT DATA (REFETCH FIRST)
        const submissionDocument = await fb.submissionsCollection.doc(this.$store.state.submission.id).get()
        const submissionDoc = submissionDocument.data()
        submissionDoc.id = submissionDocument.id
        await fb.submissionsCollection.doc(this.$store.state.submission.id).collection('revisions').add(submissionDoc)

        await fb.submissionsCollection.doc(this.$store.state.submission.id).update(this.$store.state.submission)
        this.comment = ''
      }
    }
  }
}
</script>

<style scoped>
.comment-icon {
 float: right;
 padding-top: 4px;
 font-size: 25px;
}

#commentsInterface {
  background-color: white;
  border-radius: 4px;
  text-align: center;
}
ul {
  list-style-type: none;
  text-align: left;
}

li {
  background-color: #f1f1f1;
  border-radius: 12px;
  margin: 5px 0;
  padding: 10px 20px;
}

li:before {
  content: "\f10d"; /* FontAwesome comment icon */
  font-family: 'Font Awesome 5 Pro';
  margin-right: 10px;
}
</style>
<template>
  <div id="submissionsTable" class="container pt-4" v-if="$store.state.plugin.farms && $store.state.farm.id && $store.state.plugin.farms.includes($store.state.farm.id)">
    <h5 class="text-center mb-4">{{ $store.state.farm.name }} Submissions</h5>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Form</th>
          <th scope="col">Label</th>
          <th scope="col">Progress</th>
          <th scope="col">Score</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="submission in $store.state.submissions" :key="submission.id">
          <td>{{ pairedForm(submission).name }} <span
              :class="[(submission.status === 'draft') ? 'text-warning' : 'text-success']"
            >{{ submission.status }}</span>
          </td>
          <td v-if="submission.label">{{ submission.label }}</td>
          <td v-else>No Label</td>
          <td>
            <div
              class="progress mt-2"
              role="progressbar"
              aria-label="Form Progress"
              :aria-valuenow="submission.progress"
              aria-valuemin="0"
              aria-valuemax="100"
              style="height: 10px"
            >
              <div class="progress-bar" :style="'width:' + ' ' + submission.progress + '%'"></div>
            </div>
          </td>
          <td>
            <span class="text-light p-1 rounded" :style="'background-color: ' + colorCalculation(submission.scores.total, submission.scores.potential)">{{ submission.scores.total }} / {{ submission.scores.potential }}</span>
          </td>
          <td class="d-flex justify-content-end">
            <button
              v-if="submission.actionPlans && submission.actionPlans.length > 0"
              class="btn btn-primary float-end btn-sm"
              @click="launchForm(submission)"
            ><i class="fas fa-list"></i> Acion Plans</button>
            <button
              v-if="submission.status && submission.status === 'draft'"
              class="btn btn-danger float-end btn-sm me-1"
              @click="confirmDelete(submission)"
            ><i class="fas fa-minus"></i></button>
            <button
              v-if="submission.status && submission.status === 'draft'"
              class="btn btn-primary float-end btn-sm"
              @click="launchForm(submission)"
            >Continue <i class="fas fa-arrow-right"></i></button>
            <button
              v-if="submission.status && submission.status === 'submitted'"
              class="btn btn-primary float-end btn-sm"
              disabled
            >Submitted</button>
            <button
              v-if="submission.status && submission.status === 'audited'"
              class="btn btn-primary float-end btn-sm"
              @click="launchForm(submission)"
            >Review  <i class="fas fa-arrow-right"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <ConfirmModal
    v-if="showDeleteConfirm"
    :text="'Are you sure you want to delete this submission?'"
    @confirmed="handleDeleteConfirmation"
  />
</template>

<script>
import ConfirmModal from '../ConfirmModal.vue'
import fb from '../../firebaseConfig'

export default {
  components: {
    ConfirmModal,
  },
  computed: {
    forms: {
      get () {
        return this.$store.state.forms
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'forms', value: val })
      }
    },
    form: {
      get () {
        return this.$store.state.form
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'form', value: val })
      }
    },
    mode: {
      get () {
        return this.$store.state.mode
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'mode', value: val })
      }
    },
    submission: {
      get () {
        return this.$store.state.submission
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'submission', value: val })
      }
    },
    submissions: {
      get () {
        return this.$store.state.submissions
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'submissions', value: val })
      }
    }
  },
  data() {
    return {
      showDeleteConfirm: false,
      submissionToDelete: null,
    }
  },
  methods: {
    colorCalculation(total, potential) {
      const ratio = (total / potential) * 100
      let color

      if (ratio < 33) {
        color = '#e795b7' // Red for low
      } else if (ratio >= 33 && ratio <= 66) {
        color = '#825f4e' // Yellow for medium
      } else {
        color = '#606b38' // Green for high
      }

      return color
    },
    pairedForm (submission) {
      let pairedForm = false
      this.forms.forEach(form => {
        if (form.id === submission.formId) {
          pairedForm = form
        }
      })
      return pairedForm || {name: ''}
    },
    launchForm (submission) {
      this.submission = submission
      this.form = this.pairedForm(submission)
      this.mode = 'form'
    },
    confirmDelete(submission) {
      this.submissionToDelete = submission;
      this.showDeleteConfirm = true;
    },
    async handleDeleteConfirmation(confirmed) {
      if (confirmed && this.submissionToDelete) {
        try {
          // Delete the Firestore document
          await fb.submissionsCollection.doc(this.submissionToDelete.id).delete();
          
          location.reload()
        } catch (error) {
          console.error('Error deleting submission:', error);
          // Show an error message
          this.$store.commit('setAlert', {
            active: true,
            level: 'alert-danger',
            message: 'Error deleting submission',
            timeout: 5000
          });
        }
      }
      this.showDeleteConfirm = false;
      this.submissionToDelete = null;
    },
  }
}
</script>
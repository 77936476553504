<template>
  <div id="adminForms">
    <div class="container">
      <button
        type="button"
        class="btn btn-primary btn-sm mb-2"
        @click="createForm()"
        v-if="$store.state.roles.includes('Administrator')"
      ><i class="fas fa-plus"></i> Create Form</button>
      <!-- FORMS TABLE -->
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Form Name</th>
            <th class="d-flex justify-content-end" scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="form in $store.state.forms" :key="form.id">
            <td>{{ form.name }}</td>
            <td class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-secondary btn-sm ms-1"
                @click="editForm(form)"
                v-if="$store.state.roles.includes('Administrator')"
              ><i class="fas fa-edit"></i> Edit</button>
              <button
                type="button"
                class="btn btn-secondary btn-sm ms-1"
                @click="previewForm(form)"
                v-if="$store.state.roles.includes('Administrator')"
              ><i class="fas fa-eye"></i> Preview</button>
              <button
                type="button"
                class="btn btn-secondary btn-sm ms-1"
                @click="launchSubmissions(form)"
              ><i class="fas fa-list"></i> Submissions</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    previewForm (form) {
      this.$store.commit('setStateProperty', { property: 'form', value: form })
      this.$store.commit('setStateProperty', { property: 'submission', value: { id: 'preview' } })
      this.$store.commit('setStateProperty', { property: 'adminMode', value: 'form' })
    },
    launchSubmissions (form) {
      this.$store.commit('setStateProperty', { property: 'form', value: form })
      this.$store.dispatch('adminFetchSubmissions')
      this.$store.commit('setStateProperty', { property: 'adminMode', value: 'submissions' })
    },
    createForm () {
      this.$store.commit('setStateProperty', { property: 'form', value: {} })
      this.$store.commit('setStateProperty', { property: 'editMode', value: false })
      this.$store.commit('setStateProperty', { property: 'modalCreateForm', value: true })
    },
    editForm (form) {
      this.$store.commit('setStateProperty', { property: 'form', value: form })
      this.$store.commit('setStateProperty', { property: 'editMode', value: true })
      this.$store.commit('setStateProperty', { property: 'modalCreateForm', value: true })
    }
  }
}
</script>

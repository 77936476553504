<template>
  <div id="formsGrid" class="container pt-4">
    <h1 class="text-center mb-4">NYWGF Sustainability Portal</h1>
    <!-- AWAITING APPROVAL NOTICE -->
    <div v-if="$store.state.plugin.farms && $store.state.farm.id && !$store.state.plugin.farms.includes($store.state.farm.id)">
      <h2 class="text-center mb-4 mt-5">Awaiting Approval</h2>
      <p class="text-center">You will be notified when you are approved!</p>
    </div>
    <!-- FARM APPROVED -->
    <div
      v-if="$store.state.plugin.farms && $store.state.farm.id && $store.state.plugin.farms.includes($store.state.farm.id)"
      class="row"
    >
      <div class="col-md-4" v-for="form in forms" :key="form.id">
        <div v-if="form.published" class="card">
          <div class="card-body">
            <h5 class="card-title">{{ form.name }}</h5>
            <button
              class="btn btn-primary w-100"
              @click="launchForm(form)"
            ><i class="fas fa-plus"></i> Start New</button>
          </div>
        </div>
      </div>
    </div>
    <!-- SUBMISSIONS TABLE -->
    <SubmissionsTable />
  </div>
</template>

<script>
import SubmissionsTable from './SubmissionsTable.vue'

export default {
  components: {
    SubmissionsTable
  },
  computed: {
    forms: {
      get () {
        return this.$store.state.forms
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'forms', value: val })
      }
    },
    form: {
      get () {
        return this.$store.state.form
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'form', value: val })
      }
    },
    mode: {
      get () {
        return this.$store.state.mode
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'mode', value: val })
      }
    },
    submission: {
      get () {
        return this.$store.state.submission
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'submission', value: val })
      }
    }
  },
  methods: {
    launchForm (form) {
      this.submission = {}
      this.form = form
      this.mode = 'form'
    }
  }
}
</script>
<template>
  <div id="actionPlansReport">
    <h2>Action Plans <button @click="printActionPlans" class="btn btn-sm btn-primary"><i class="fas fa-print"></i></button></h2>
    <hr>
    <table class="table">
      <tbody>
        <tr v-for="plan in actionPlans" :key="plan.plan" class="w-100">
          <td class="row">
            <div class="col-1">
              <div
                v-if="plan.completed"
                class="btn btn-success"
                @click="toggleComplete(plan, false)"
              >
                <i class="far fa-check-square"></i>
              </div>
              <div
                v-else
                class="btn btn-warning"
                @click="toggleComplete(plan, true)"
              >
                <i class="far fa-square"></i>
              </div>
            </div>
            <div class="col-11">
              <p class="mb-0"><strong>{{ plan.group.name }} / {{ plan.field.name }}</strong></p>
              <p class="mb-0 underline">Complete By: {{ formatTimeline(plan.timeline) }}</p>
              <div v-html="displayWithLineBreaks(plan.plan)"></div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import fb from '../../firebaseConfig'

export default {
  data () {
    return {
      actionPlans: []
    }
  },
  mounted () {
    this.initPlans()
  },
  computed: {
    form: {
      get () {
        return this.$store.state.form
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'form', value: val })
      }
    },
    submission: {
      get () {
        return this.$store.state.submission
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'submission', value: val })
      }
    }
  },
  methods: {
    async toggleComplete (plan, value) {
      this.submission.actionPlans[plan.field.id][plan.index].completed = value
      // Add a Revision
      // SAVE REVISION FROM CURRENT DATA (REFETCH FIRST)
      const submissionDocument = await fb.submissionsCollection.doc(this.$store.state.submission.id).get()
      const submissionDoc = submissionDocument.data()
      submissionDoc.id = submissionDocument.id
      await fb.submissionsCollection.doc(this.$store.state.submission.id).collection('revisions').add(submissionDoc)
      await fb.submissionsCollection.doc(this.$store.state.submission.id).update(this.$store.state.submission)
      this.initPlans()
    },
    initPlans() {
      this.actionPlans = []
      // Create a map to store the order of each field
      const fieldOrderMap = new Map()
      this.form.groups.forEach(group => {
        group.fields.forEach((field, index) => {
          fieldOrderMap.set(field.id, index)
        })
      })

      const actionPlansKeys = Object.keys(this.submission.actionPlans)
      actionPlansKeys.forEach(key => {
        const actionPlans = this.submission.actionPlans[key]
        actionPlans.forEach((plan, i) => {
          const actionPlan = plan
          this.form.groups.forEach(group => {
            group.fields.forEach(field => {
              if (field.id === key) {
                actionPlan.group = group
                actionPlan.field = field
                actionPlan.index = i
                this.actionPlans.push(actionPlan)
              }
            })
          })
        })
      })

      // Sort the actionPlans based on the order in fieldOrderMap
      this.actionPlans.sort((a, b) => {
        const orderA = fieldOrderMap.get(a.field.id)
        const orderB = fieldOrderMap.get(b.field.id)
        return orderA - orderB
      })
      console.log(this.actionPlans)
    },
    displayWithLineBreaks (text) {
      return text.replace(/\n/g, '<br>')
    },
    formatTimeline (date) {
      console.log(date)
      if (!date) return ''

      const options = {
        year: 'numeric',
        month: 'long', 
        day: 'numeric', 
        timeZone: 'UTC' 
      }

      if (date.seconds) {
        const dateFromSeconds = new Date(date.seconds * 1000)
        return dateFromSeconds.toLocaleDateString('en-US', options)
      } else {
        return new Intl.DateTimeFormat('en-US', options).format(new Date(date))
      }
    },
    printActionPlans() {
      let printContent = this.actionPlans.map(plan => {
        return `
          <div class="print-row">
            <input type="checkbox" ${plan.completed ? 'checked' : ''} disabled>
            <span>${plan.group.name} / ${plan.field.name}</span>
            <div>${this.displayWithLineBreaks(plan.plan)}</div>
            <div><small>Complete By: ${this.formatTimeline(plan.timeline)}</small></div>
          </div>
        `;
      }).join('')

      let printWindow = window.open('', 'PRINT', 'height=600,width=800')
      printWindow.document.write('<html><head><title>Action Plans</title>')
      // Include styles here if necessary
      printWindow.document.write('</head><body>')
      printWindow.document.write(printContent)
      printWindow.document.write('</body></html>')
      printWindow.document.close() 
      printWindow.focus()

      setTimeout(() => {
        printWindow.print()
        printWindow.close()
      }, 1000)
    }
  }
}
</script>

<style>
@media print {
  .print-row {
    margin-bottom: 10px;
  }
  .print-row input[type="checkbox"] {
    margin-right: 5px;
  }
  .print-row span {
    font-weight: bold;
  }
  /* Other styles as needed */
}
</style>

import { createStore } from 'vuex';
import actions from './actions';
import mutations from './mutations';
import fb from '../firebaseConfig';

const store = createStore({
  state: {
    adminMode: false,
    adminSubmissionsMode: 'list',
    alert: {
      active: false,
      message: 'This is a basic alert',
      level: 'alert-info'
    },
    currentUser: {},
    editMode: false,
    farm: {},
    farms: [],
    forms: [],
    form: {},
    group: {},
    modalCreateForm: false,
    mode: false,
    plugin: {},
    requestPassword: false,
    roles: [],
    submissions: [],
    submission: {},
    tempForm: {
      name: '',
      description: '',
      published: false,
      groups: []
    },
    userProfile: {}
  },
  getters: {
  },
  mutations,
  actions,
  modules: {
  },
});

// handle page reload
// eslint-disable-next-line
fb.auth.onAuthStateChanged((user: any) => {
  if (user) {
    store.commit('setCurrentUser', user);
    store.dispatch('fetchUserProfile');
    // eslint-disable-next-line
    fb.usersCollection.doc(user.uid).onSnapshot((doc: any) => {
      store.commit('setUserProfile', doc.data());
    });
  }
});

export default store;

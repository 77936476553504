import fb from '../firebaseConfig'
import router from '../router'

export default {
  adminFetchSubmissions ({ commit, state}: any) {
    commit('setStateProperty', { property: 'submissions', value: [] })
    fb.submissionsCollection.where('formId', '==', state.form.id).get().then(subsDocs => {
      // eslint-disable-next-line
      let subsData: any = []
      subsDocs.forEach(doc => {
        const sub = doc.data()
        sub.id = doc.id
        subsData.push(sub)
      })
      commit('setStateProperty', { property: 'submissions', value: subsData })
    }).catch(err => {
      console.log(err)
    })
  },
  async fetchAll ({ dispatch }: any) {
    await dispatch('fetchPluginData')
    await dispatch('fetchFarm')
    await dispatch('fetchForms')
    await dispatch('fetchUserProfile')
    await dispatch('fetchSubmissions')
    return true
  },
  async fetchFarm({ commit }: any) {
    const farmId = router.currentRoute.value.params.farmId
    if (typeof farmId === 'string') {
      await fb.farmsCollection.doc(farmId).get().then(farmDoc => {
        const farmData = farmDoc.data()
        if (farmData) {
          farmData.id = farmDoc.id
          commit('setStateProperty', { property: 'farm', value: farmData })
        } else {
          console.log('ERROR COMMITTING FARM DATA')
        }
        return true
      })
    } else {
      console.log('ERROR GETTING FARM ID')
    }
  },
  fetchFarms ({ commit }: any) {
    commit('setStateProperty', { property: 'farms', value: [] })
    fb.farmsCollection.where('plugins', 'array-contains', 'nywgf').get().then(farmsDocs => {
      // eslint-disable-next-line
      let farmsData: any = []
      farmsDocs.forEach(doc => {
        const farm = doc.data()
        farm.id = doc.id
        farmsData.push(farm)
      })
      // eslint-disable-next-line
      commit('setStateProperty', { property: 'farms', value: farmsData })
    }).catch(err => {
      console.log(err)
    })
  },
  fetchForms ({ commit }: any) {
    commit('setStateProperty', { property: 'forms', value: [] })
    fb.formsCollection.get().then(formsDocs => {
      // eslint-disable-next-line
      let formsData: any = []
      formsDocs.forEach(doc => {
        const form = doc.data()
        form.id = doc.id
        formsData.push(form)
      })
      // eslint-disable-next-line
      commit('setStateProperty', { property: 'forms', value: formsData })
    }).catch(err => {
      console.log(err)
    })
  },
  // eslint-disable-next-line
  async fetchUserProfile({ commit, state }: any) {
    // eslint-disable-next-line
    await fb.usersCollection.doc(state.currentUser.uid).get().then((res: any) => {
      commit('setUserProfile', res.data());
      return true
      // eslint-disable-next-line
    }).catch((err: any) => {
      // console.log(err);
      return false
    });
  },
  fetchPluginData ({ commit, state}: any) {
    fb.pluginData.get().then(pluginDoc => {
      const pluginData: any = pluginDoc.data()
      pluginData.id = pluginDoc.id
      commit('setStateProperty', { property: 'plugin', value: pluginData })
      const roles: any = []
      const userId = state.currentUser.uid
      pluginData.administrators.forEach((administrator: any) => {
        if (administrator === userId) {
          roles.push('Administrator')
        }
      })
      pluginData.auditors.forEach((auditor: any) => {
        if (auditor === userId) {
          roles.push('Auditor')
        }
      })
      commit('setStateProperty', { property: 'roles', value: roles })
    })
  },
  fetchSubmissions ({ commit, state }: any) {
    commit('setStateProperty', { property: 'submissions', value: [] })
    // fb.submissionsCollection.where('createdBy', '==', state.currentUser.uid).get().then(subsDocs => {
    fb.submissionsCollection.where('farmId', '==', state.farm.id).get().then(subsDocs => {
      // eslint-disable-next-line
      let subsData: any = []
      subsDocs.forEach(doc => {
        const sub = doc.data()
        sub.id = doc.id
        subsData.push(sub)
      })
      // eslint-disable-next-line
      commit('setStateProperty', { property: 'submissions', value: subsData })
    }).catch(err => {
      console.log(err)
    })
  },
  async initApp ({ commit, dispatch }: any) {
    await dispatch('fetchAll')
    commit('setStateProperty', { property: 'mode', value: 'grid' })
  }
};

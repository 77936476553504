<template>
  <div id="confirmModal">
    <div
      class="confirm"
    >
      <p>{{ text }}</p>
      <button
        class="btn btn-danger me-1"
        @click="$emit('confirmed', true)"
      >
        Yes
      </button>
      <button
        class="btn btn-secondary"
        @click="$emit('confirmed', false)"
      >
        No
      </button>
    </div><!-- /.confirm -->
  </div><!-- /#confirm -->
</template>

<script>
export default {
  props: ['text']
}
</script>

<style scoped>
.confirm {
  position: fixed;
  padding: 40px 20px;
  border-radius: 4px;
  z-index: 9999999999999;
  top: 20%;
  left: 80px;
  width: calc(100% - 160px);
  text-align: center;
  background-color: #eee;
  box-shadow: 1px 2px 3px rgba(0,0,0,0.4);
  color: #000;
}
</style>

<template>
  <div id="pluginInterface">
    <AlertModal
      v-if="$store.state.alert.active == true"
    />
    <router-view/>
  </div>
</template>

<script>
import AlertModal from './components/AlertModal.vue'

export default {
  components: {
    AlertModal
  }
}
</script>

<template>
  <div id="submissionsReports">
    <div class="container">
      <div class="row mt-4">
        <!-- SUBMISSIONS COUNT -->
        <div class="col-md-4">
          <div class="card text-center pt-3">
            <h3>{{ submissions.length }}</h3>
            <p>Submissions</p>
          </div>
        </div>
        <!-- AVERAGE SCORE -->
        <div class="col-md-4">
          <div class="card text-center pt-3">
            <h3>{{ scoresMean }} / {{ submissions[0].scores.potential }}</h3>
            <p>Average Score</p>
          </div>
        </div>
        <!-- MEDIAN SCORE -->
        <div class="col-md-4">
          <div class="card text-center pt-3">
            <h3>{{ scoresMedian }} / {{ submissions[0].scores.potential }}</h3>
            <p>Median Score</p>
          </div>
        </div>
      </div>
      <!-- GROUP SCORES -->
      <div class="group-stats mt-4" v-for="scoresGroupsMean in scoresGroupsMeans" :key="scoresGroupsMean.name">
        <p>{{ scoresGroupsMean.name }} (Average Score: {{ scoresGroupsMean.average }} / {{ scoresGroupsMean.potential }})</p>
        <div
          class="progress mb-2"
          role="progressbar"
          aria-label="Total Score"
          :aria-valuenow="scoresGroupsMean.average"
          aria-valuemin="0"
          :aria-valuemax="scoresGroupsMean.potential"
          style="height: 4px"
        >
          <div
            class="progress-bar"
            :style="'width:' + ' ' + widthCalculation(scoresGroupsMean.average, scoresGroupsMean.potential) + '%;background-color: ' + colorCalculation(scoresGroupsMean.average, scoresGroupsMean.potential)"
          ></div>
        </div>
      </div>
      <!-- CATEGORIES SCORES -->
      <div class="group-stats mt-4" v-for="scoresCategoriesMean in scoresCategoriesMeans" :key="scoresCategoriesMean.name">
        <p>{{ scoresCategoriesMean.name }} (Average Score: {{ scoresCategoriesMean.average }} / {{ scoresCategoriesMean.potential }})</p>
        <div
          class="progress mb-2"
          role="progressbar"
          aria-label="Total Score"
          :aria-valuenow="scoresCategoriesMean.average"
          aria-valuemin="0"
          :aria-valuemax="scoresCategoriesMean.potential"
          style="height: 4px"
        >
          <div
            class="progress-bar"
            :style="'width:' + ' ' + widthCalculation(scoresCategoriesMean.average, scoresCategoriesMean.potential) + '%;background-color: ' + colorCalculation(scoresCategoriesMean.average, scoresCategoriesMean.potential)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      scoresMean: 0,
      scoresMedian: 0,
      scoresPotential: 0,
      scoresGroupsMeans: [],
      scoresCategoriesMeans: []
    }
  },
  computed: {
    form: {
      get () {
        return this.$store.state.form
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'form', value: val })
      }
    },
    submissions: {
      get () {
        return this.$store.state.submissions
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'submissions', value: val })
      }
    }
  },
  mounted () {
    this.calculateStats()
  },
  methods: {
    calculateStats () {
      // Total Scores
      const scoresArray = this.submissions.map(item => item.scores.total)
      this.scoresMean = this.calculateMean(scoresArray)
      this.scoresMedian = this.calculateMedian(scoresArray)

      // Calculate Groups Means and potential
      const groupAverages = {}
      this.submissions.forEach(submission => {
        if (Array.isArray(submission.scores.groups)) {
          submission.scores.groups.forEach(group => {
            if (!groupAverages[group.name]) {
              groupAverages[group.name] = {
                total: 0,
                count: 0,
                potential: group.potential // assuming all groups with the same name have the same potential
              }
            }
            groupAverages[group.name].total += group.total
            groupAverages[group.name].count += 1
          })
        }
      })

      this.scoresGroupsMeans = Object.keys(groupAverages).map(groupName => {
        const avg = groupAverages[groupName].total / groupAverages[groupName].count
        return {
          name: groupName,
          average: avg,
          potential: groupAverages[groupName].potential
        }
      })

      // Calculate Categories Means and potential
      const categoryAverages = {}
      this.submissions.forEach(submission => {
        if (Array.isArray(submission.scores.categories)) {
          submission.scores.categories.forEach(category => {
            if (!categoryAverages[category.name]) {
              categoryAverages[category.name] = {
                total: 0,
                count: 0,
                potential: category.potential // assuming all categories with the same name have the same potential
              }
            }
            categoryAverages[category.name].total += category.total
            categoryAverages[category.name].count += 1
          })
        }
      })

      this.scoresCategoriesMeans = Object.keys(categoryAverages).map(categoryName => {
        const avg = categoryAverages[categoryName].total / categoryAverages[categoryName].count
        return {
          name: categoryName,
          average: avg,
          potential: categoryAverages[categoryName].potential
        }
      })
    },
    calculateMean (arr) {
      const sum = arr.reduce((acc, val) => acc + val, 0)
      return sum / arr.length
    },
    calculateMedian (arr) {
      const sortedArr = [...arr].sort((a, b) => a - b)
      const mid = Math.floor(sortedArr.length / 2)

      if (sortedArr.length % 2 === 0) {
        return (sortedArr[mid - 1] + sortedArr[mid]) / 2
      }
      return sortedArr[mid]
    },
    // colorCalculation (total, potential) {
    //   const hue = Math.floor((120 / potential) * total)
    //   return `hsl(${hue}, 100%, 50%, 1)`
    // },
    colorCalculation(total, potential) {
      const ratio = (total / potential) * 100
      let color

      if (ratio < 33) {
        color = '#e795b7' // Red for low
      } else if (ratio >= 33 && ratio <= 66) {
        color = '#8a3b61' // Yellow for medium
      } else if (ratio >= 33 && ratio <= 66) {
        color = '#606b38' // Brown for medium
      } else {
        color = '#9aca3c' // Green for high
      }

      return color
    },
    widthCalculation (total, potential) {
      return (total / potential) * 100
    }
  }
}
</script>
<template>
  <div id="formStats">
    <h2>Stats</h2>
    <p>Please review the scores below. When you are ready, please click the 'Final Submission' button at the bottom of the page.</p>
    <div v-if="aggregatedScores">

      <!-- Group Stats -->
      <div class="group-stats mt-4" v-for="group in aggregatedScores.groups" :key="group.name">
        <p>{{ group.name }} (Score: {{ group.total }} / {{ group.potential }})</p>
        <div
          class="progress mb-2"
          role="progressbar"
          aria-label="Total Score"
          :aria-valuenow="group.total"
          aria-valuemin="0"
          :aria-valuemax="group.potential"
          style="height: 4px"
        >
          <div
            class="progress-bar"
            :style="'width:' + ' ' + widthCalculation(group.total, group.potential) + '%;background-color: ' + colorCalculation(group.total, group.potential)"
          ></div>
        </div>
      </div>

      <!-- Category Stats -->
      <div class="category-stats">
        <div v-for="category in aggregatedScores.categories" :key="category.name" class="mt-4">
          <p>{{ category.name }} (Score: {{ category.total }} / {{ category.potential }})</p>
          <div
            class="progress mb-2"
            role="progressbar"
            aria-label="Total Score"
            :aria-valuenow="category.total"
            aria-valuemin="0"
            :aria-valuemax="category.potential"
            style="height: 4px"
          >
            <div
              class="progress-bar"
              :style="'width: ' + widthCalculation(category.total, category.potential) + '%;background-color: ' + colorCalculation(category.total, category.potential)"
            ></div>
          </div>
        </div>
      </div>

      <!-- Total Score -->
      <div class="total-score mt-4">
        <h1>Total Score: {{ aggregatedScores.total }} / {{ aggregatedScores.potential }}</h1>
        <div
          class="progress mb-2"
          role="progressbar"
          aria-label="Total Score"
          :aria-valuenow="aggregatedScores.total"
          aria-valuemin="0"
          :aria-valuemax="aggregatedScores.potential"
        >
          <div class="progress-bar" :style="'width:' + ' ' + widthCalculation(aggregatedScores.total, aggregatedScores.potential) + '%;background-color: ' + colorCalculation(aggregatedScores.total, aggregatedScores.potential)"></div>
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
export default {
  data () {
    return {
      aggregatedScores: null
    }
  },
  created () {
    this.aggregateScores()
  },
  computed: {
    form: {
      get () {
        return this.$store.state.form
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'form', value: val })
      }
    },
    submission: {
      get () {
        return this.$store.state.submission
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'submission', value: val })
      }
    }
  },
  methods: {
    // colorCalculation (total, potential) {
    //   const hue = Math.floor((120 / potential) * total)
    //   return `hsl(${hue}, 100%, 50%, 1)`
    // },
    colorCalculation(total, potential) {
      const ratio = (total / potential) * 100
      let color

      if (ratio < 33) {
        color = '#e795b7' // Red for low
      } else if (ratio >= 33 && ratio <= 66) {
        color = '#8a3b61' // Yellow for medium
      } else if (ratio >= 33 && ratio <= 66) {
        color = '#606b38' // Brown for medium
      } else {
        color = '#9aca3c' // Green for high
      }

      return color
    },
    widthCalculation (total, potential) {
      return (total / potential) * 100
    },
    aggregateScores () {
      const groups = this.form.groups
      const answers = this.submission.answers
      const corrections = this.submission.corrections

      let totalScore = 0
      let totalPotential = 0
      const groupStats = []
      const categoryStats = {}
      const categoryPotential = {}

      groups.forEach(group => {
        let groupTotal = 0
        let groupPotential = 0
        let hasRubric = false

        group.fields.forEach(field => {
          if (field.type !== 'rubric') return

          hasRubric = true

          const answer = answers[field.id] || 0
          const correction = corrections[field.id] || false
          const maxScore = field.indicators.length

          if (!correction) {
            groupTotal += answer
            totalScore += answer
          } else {
            groupTotal += correction
            totalScore += correction
          }
          groupPotential += maxScore
          totalPotential += maxScore

          if (field.categories) {
            field.categories.forEach(category => {
              if (!categoryStats[category.name]) {
                categoryStats[category.name] = 0
                categoryPotential[category.name] = 0
              }
              if (!correction) {
                categoryStats[category.name] += answer
              } else {
                categoryStats[category.name] += correction
              }
              categoryPotential[category.name] += maxScore
            })
          }
        })

        if (hasRubric) {
          groupStats.push({
            name: group.name,
            total: groupTotal,
            potential: groupPotential
          })
        }
      })

      this.aggregatedScores = {
        total: totalScore,
        potential: totalPotential,
        groups: groupStats,
        categories: Object.entries(categoryStats).map(([name, total]) => ({
          name,
          total,
          potential: categoryPotential[name],

        }))
      }
      this.submission.scores = this.aggregatedScores
    }
  }
}
</script>
<template>
  <div id="alertModal" class="p-5">
    <div
      class="alert"
      role="alert"
      :class="$store.state.alert.level"
    >
      <button @click="$store.commit('setAlert', { active: false })" type="button" class="btn-close close" data-dismiss="alert" aria-label="Close">
      </button>
      {{ $store.state.alert.message }}
    </div><!-- /.alert -->
  </div><!-- /#alert -->
</template>

<script></script>

<style scoped>
#alertModal {
  position: fixed;
  z-index: 99;
  top: 60px;
  left: 0px;
  width: 100%;
  text-align: center;
}
</style>

<template>
  <div>
    <label for="scaleMin">Scale Min</label>
    <input v-model="localField.scaleMin" type="number" id="scaleMin" class="form-control" />

    <label for="scaleMax">Scale Max</label>
    <input v-model="localField.scaleMax" type="number" id="scaleMax" class="form-control" />

    <label for="scaleMinLabel">Scale Min Label</label>
    <input v-model="localField.scaleMinLabel" type="text" id="scaleMinLabel" class="form-control" />

    <label for="scaleMaxLabel">Scale Max Label</label>
    <input v-model="localField.scaleMaxLabel" type="text" id="scaleMaxLabel" class="form-control" />

    <label for="scaleStep">Scale Step</label>
    <input v-model="localField.scaleStep" type="number" id="scaleStep" class="form-control" />

    <div v-for="(indicator, index) in localField.indicators" :key="index" class="form-group">
      <label :for="`indicator-${index}`">Step {{ index + 1 }} Indicators</label>
      <!-- <input v-model="localField.indicators[index]" :id="`indicator-${index}`" type="text" class="form-control" /> -->
      <WysiwygEditor
        v-model="localField.indicators[index]"
        :id="`indicator-${index}`"
      />
    </div>
  </div>
</template>

<script>
import WysiwygEditor from './WysiwygEditor.vue'
export default {
  props: ['field'],
  watch: {
    localField: {
      deep: true,
      handler(newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  components: {
    WysiwygEditor
  },
  computed: {
    localField: {
      get() {
        return this.field
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

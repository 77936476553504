<template>
  <div class="container-fluid mt-2">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a v-if="!adminMode && mode === 'form'" @click="backToGrid()" href="#"><i class="fas fa-arrow-left"></i> Back to Forms</a>
          <a v-if="adminMode === 'forms'" @click="adminMode = 'forms'">Forms</a>
          <a v-if="adminMode === 'submissions' || adminMode === 'form'" @click="adminMode = 'forms'" href="#">Forms</a>
        </li>
        <li
          v-if="adminMode === 'submissions' || adminMode === 'form'"
          class="breadcrumb-item active"
          aria-current="page"
        >
          <a v-if="adminMode !== 'form'" @click="adminMode = 'submissions'">{{ $store.state.form.name }}</a>
          <a v-if="adminMode === 'form'" @click="adminMode = 'submissions'" href="#">{{ $store.state.form.name }}</a>
        </li>
        <li
          v-if="adminMode === 'form'"
          class="breadcrumb-item active"
          aria-current="page"
        >{{ $store.state.submission.farm.name }}</li>
      </ol>
    </nav>
  </div>
</template>

<script>
export default {
  computed: {
    adminMode: {
      get () {
        return this.$store.state.adminMode
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'adminMode', value: val })
      }
    },
    mode: {
      get () {
        return this.$store.state.mode
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'mode', value: val })
      }
    }
  },
  methods: {
    backToGrid () {
      this.$store.dispatch('fetchAll')
      this.mode = 'grid'
    }
  }
}
</script>
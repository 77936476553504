<template>
  <nav class="navbar bg-body-tertiary">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">NYWGF myEV Plugin Admin</a>
      <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
        Menu
      </button>
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Options</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <ul class="list-group mb-5">
            <a
              @click="$store.commit('setStateProperty', { property: 'adminMode', value: 'forms'})"
              data-bs-dismiss="offcanvas"
              class="list-group-item list-group-item-action clickable"
            ><i class="fas fa-clipboard-list"></i> Forms</a>
            <a
              @click="$store.commit('setStateProperty', { property: 'adminMode', value: 'farms'})"
              data-bs-dismiss="offcanvas"
              class="list-group-item list-group-item-action clickable"
            ><i class="fas fa-users"></i> Farms</a>
            <a
              @click="logOut()"
              class="list-group-item list-group-item-action clickable"
            ><i class="fas fa-sign-out-alt"></i> Logout</a>
          </ul>

          <span
            v-for="role in $store.state.roles"
            v-bind:key="role"
            class="badge rounded-pill text-bg-success me-1"
          >{{ role }}</span>
          <div v-if="$store.state.userProfile.updatedOn">
            <p>{{ $store.state.userProfile.email }}<br />
              <small>({{ $store.state.currentUser.uid }})</small>
            </p>
          </div>

        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import fb from '../../firebaseConfig.ts'

export default {
  methods: {
    logOut () {
      console.log('Log out')
      fb.auth.signOut().then(() => {
        console.log('User logged out successfully')
        // User is now signed out
        // Redirect to login page or update UI accordingly
        this.$router.push('/')
      }).catch(error => {
        console.error('Sign out error:', error)
        // An error happened.
      })
    }
  }
}
</script>
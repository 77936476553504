<template>
  <div id="submissionsMap"></div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

export default {
  data () {
    return {
      map: null
    }
  },
  mounted () {
    this.initMap()
  },
  computed: {
    submissions: {
      get () {
        return this.$store.state.submissions
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'submissions', value: val })
      }
    }
  },
  methods: {
    launchSubmission (submission) {
      console.log(submission)
      this.submissions.forEach(sub => {
        if (submission.submissionId === sub.id) {
          this.$store.commit('setStateProperty', { property: 'submission', value: sub })
        }
      })
      this.$store.commit('setStateProperty', { property: 'adminMode', value: 'form' })
    },
    initMap() {
      mapboxgl.accessToken = 'pk.eyJ1IjoibGVyZ3AiLCJhIjoiY2p4bmI1NzNzMGN0MTNjcGx4cjF4eDBtdSJ9.2C0FEHhNZ-jGd7jgIRTrEQ'

      // New York State bounds
      const bounds = [
        [-79.76259, 40.4774], // Southwest coordinates
        [-71.18507, 45.01585]  // Northeast coordinates
      ]
      this.map = new mapboxgl.Map({
        container: 'submissionsMap',
        style: 'mapbox://styles/mapbox/streets-v11',
        bounds: bounds,
        fitBoundsOptions: {
          padding: { top: 20, bottom: 20, left: 20, right: 20 },
          animate: false
        }
      })

      this.map.on('load', () => {
        // Add click event to show popup
        this.map.on('click', 'submissions', e => {
          if (!e.features || e.features.length === 0) return
          
          const coordinates = e.features[0].geometry.coordinates.slice()
          const { farmName, userName, status, submissionId } = e.features[0].properties
          
          // Create popup content
          const popupContent = document.createElement('div')
          popupContent.innerHTML = `
            <h5>${farmName}</strong></h5>
            <p>${userName}</p>
            <a class="btn btn-sm btn-primary" id="launchSubmissionLink">View Submission</a>
          `
          
          // Create and show the popup
          const popup = new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setDOMContent(popupContent)
            .addTo(this.map)
          
          // Manually attach click event listener
          const link = popupContent.querySelector('#launchSubmissionLink')
          link.addEventListener('click', () => {
            this.launchSubmission({ farmName, userName, status, submissionId })
          })
        })

        // Change the cursor to a pointer when the mouse is over the places layer.
        this.map.on('mouseenter', 'submissions', () => {
          this.map.getCanvas().style.cursor = 'pointer'
        })

        // Change it back to a grab when it leaves.
        this.map.on('mouseleave', 'submissions', () => {
          this.map.getCanvas().style.cursor = ''
        })

        this.addPoints()
      })
    },
    addPoints() {
      if (!this.map || !this.map.isStyleLoaded()) return

      // Create a GeoJSON object from submissions
      const geojson = {
        type: 'FeatureCollection',
        features: this.submissions.map(submission => ({
          type: 'Feature',
          properties: {
            status: submission.status,
            farmName: submission.farm.name,
            userName: submission.userName,
            submissionId: submission.id
          },
          geometry: {
            type: 'Point',
            coordinates: [
              submission.farm.geolocation.longitude,
              submission.farm.geolocation.latitude
            ]
          }
        }))
      }

      // Add a GeoJSON layer to the map
      this.map.addLayer({
        id: 'submissions',
        type: 'circle',
        source: {
          type: 'geojson',
          data: geojson
        },
        paint: {
          'circle-radius': 12,
          // Color circle by status property
          'circle-color': [
            'match',
            ['get', 'status'],
            'submitted', 'green',
            'draft', 'yellow',
            'rejected', 'red',
            'white'  // fallback color
          ]
        }
      })
    }
  }
}
</script>

<style>
#submissionsMap {
  width: 100%;
  height: calc(100vh - 300px);
}
</style>
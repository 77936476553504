<template>
  <div id="rubricField">
    <h4>{{ fieldData.name }}<span v-if="fieldData.required" class="text-danger">*</span></h4>
    <p>
      <span
        v-for="(category, i) in fieldData.categories"
        v-bind:key="i"
        class="badge rounded-pill text-bg-warning me-1"
      >{{ category.name }}</span>
    </p>
    <p v-if="$store.state.adminMode"></p>
    <p v-else v-html="fieldData.description"></p>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th
              v-for="(indicator, i) in fieldData.indicators"
              v-bind:key="i"
              scope="col"
              class="text-center"
            >
              {{ i + 1 }}
              <span v-if="i === 0">{{ scaleMinLabel }}</span>
              <span v-if="i === (fieldData.scaleMax - 1)">({{ fieldData.scaleMaxLabel }})</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="$store.state.adminMode && $store.state.submission.id != 'preview'">
            <td
              v-for="(indicator, index) in fieldData.indicators"
              v-bind:key="index"
              scope="col"
              :width="widthCalc()"
              :style="{ backgroundColor: getBackgroundColor(index) }"
              :class="[
                'rubric-option',
                isAnswer(index) ? 'answer' : '',
                isCorrection(index) ? 'correction' : ''
              ]"
              @click="selectOption(index)"
            ></td>
          </tr>
          <tr v-else>
            <td
              v-for="(indicator, index) in fieldData.indicators"
              v-bind:key="index"
              scope="col"
              v-html="indicator"
              :width="widthCalc()"
              :style="{ backgroundColor: getBackgroundColor(index) }"
              :class="[
                'rubric-option',
                isAnswer(index) ? 'answer' : '',
                isCorrection(index) ? 'correction' : ''
              ]"
              @click="selectOption(index)"
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['fieldData', 'modelValue', 'answer', 'correction'],
  methods: {
    isAnswer (index) {
      return index + 1 === this.answer
    },
    isCorrection (index) {
      return index + 1 === this.correction
    },
    widthCalc () {
      const percent = 100 / this.fieldData.indicators.length
      return percent + '%'
    },
    selectOption (index) {
      if (!this.$store.state.adminMode || this.$store.state.roles.includes('Administrator') || this.$store.state.roles.includes('Auditor')) {
        this.$emit('update:modelValue', index + 1)
      }
    },
    // getBackgroundColor(index) {
    //   const total = this.fieldData.indicators.length - 1
    //   const hue = Math.floor((120 / total) * index) // Hue ranges from 0 (red) to 120 (green)
    //   return `hsl(${hue}, 100%, 50%, 0.4)`
    // }
    getBackgroundColor(index) {
      const total = this.fieldData.indicators.length - 1
      // Define start and end colors in RGB
      const startColor = { r: 231, g: 149, b: 183 }
      const endColor = { r: 154, g: 202, b: 60 }
      
      // Calculate the difference in RGB components
      const diffColor = {
        r: endColor.r - startColor.r,
        g: endColor.g - startColor.g,
        b: endColor.b - startColor.b
      }

      // Calculate the interpolation factor (0 to 1)
      const factor = index / total

      // Interpolate each color component
      const interpolatedColor = {
        r: Math.round(startColor.r + (diffColor.r * factor)),
        g: Math.round(startColor.g + (diffColor.g * factor)),
        b: Math.round(startColor.b + (diffColor.b * factor))
      }

      // Return the interpolated color in `rgba` format with 70% opacity
      return `rgb(${interpolatedColor.r}, ${interpolatedColor.g}, ${interpolatedColor.b}, 1)`
    }
  }
}
</script>

<style scoped>
#rubricField {
  background-color: #eee;
  padding: 10px;
  border-radius: 4px;
}

.rubric-option {
  cursor: pointer;
  transition: background-color 0.3s;
}
.rubric-option:hover, .rubric-option.answer {
  border: black 4px solid;
}
.rubric-option.correction {
  border: rgb(220, 53, 69) 4px solid;
}
</style>
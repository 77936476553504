<template>
  <div id="groupInterface" class="container">
    <div v-if="group.id && group.id !== 'end'">
      <h3>{{ group.name }}</h3>
      <hr>
      <div v-for="field in group.fields" v-bind:key="field.id" class="field-item mb-4 p-2">
        <!-- STATIC TEXT FIELDS -->
        <div v-if="field.type === 'staticText'">
          <h4>{{ field.name }}</h4>
          <div v-html="field.staticText"></div>
        </div>
        <!-- RUBRIC FIELDS -->
        <RubricField
          v-if="field.type === 'rubric' && !adminMode"
          v-model="submission.answers[field.id]"
          :fieldData="field"
          :answer="submission.answers[field.id]"
          :correction="submission.corrections[field.id]"
        />
        <RubricField
          v-if="field.type === 'rubric' && adminMode"
          v-model="submission.corrections[field.id]"
          :fieldData="field"
          :answer="submission.answers[field.id]"
          :correction="submission.corrections[field.id]"
        />
        <!-- BLOCKS ATTACHMENT FIELDS -->
        <BlocksAttachmentField
          v-if="field.type === 'blocksAttachment'"
          v-model="submission.answers[field.id]"
          :fieldData="field"
          :answer="submission.answers[field.id]"
        />
        <!-- COMMENTS INTERFACE -->
        <CommentsInterface
          v-if="field.type !== 'staticText'"
          v-model="submission.comments[field.id]"
          :fieldData="field"
        />
        <!-- ACTION PLAN INTERFACE-->
        <ActionPlansInterface
          v-if="field.type !== 'staticText'"
          v-model="submission.actionPlans[field.id]"
          :fieldData="field"
        />
      </div>
    </div>
    <div v-if="group.id && group.id === 'end'">
      <div class="btn-group btn-group-sm mb-3">
        <button
          :class="['btn', finalPageMode === 'stats' ? 'btn-primary' : 'btn-secondary']"
          @click="finalPageMode = 'stats'"
        >Stats</button>
        <button
          :class="['btn', finalPageMode === 'actionReports' ? 'btn-primary' : 'btn-secondary']"
          @click="finalPageMode = 'actionReports'"
        >Action Plans</button>
      </div>
      <FormStats v-if="finalPageMode === 'stats'" />
      <ActionPlansReport v-if="finalPageMode === 'actionReports'" />
      <div v-if="!$store.state.adminMode && submission.status === 'draft'">
        <h3>FINAL SUBMISSION</h3>
        <p>By clicking the button below, I certify that my workbook is complete. This will finalize your submission and you will not be able to change your answers.</p>
        <button
          v-if="!submitting"
          @click="updateStatus('submitted', true)"
          class="btn btn-success btn-lg"
        >Final Submission</button>
        <button
          v-if="submitting"
          class="btn btn-secondary btn-lg"
        ><i class="fas fa-spinner-third fa-spin"></i> Submitting...</button>
      </div>
      <div
        v-if="$store.state.adminMode"
      >
        <h3>Form Status</h3>
        <button
          v-if="submitting"
          class="btn btn-secondary"
        ><i class="fas fa-spinner-third fa-spin"></i> Updating...</button>
        <div
          class="btn-group"
          v-if="!submitting"
        >
          <button
            :class="['btn', submission.status === 'draft' ? 'btn-primary' : 'btn-secondary']"
            @click="updateStatus('draft')"
          >Draft</button>
          <button
            :class="['btn', submission.status === 'submitted' ? 'btn-success' : 'btn-secondary']"
            @click="updateStatus('submitted')"
          >Submitted</button>
          <button
            :class="['btn', submission.status === 'audited' ? 'btn-warning' : 'btn-secondary']"
            @click="updateStatus('audited')"
          >Audited</button>
        </div>
      </div>
    </div>
    <!-- START PAGE -->
    <div v-if="!group.id">
      <!-- Import Data from old submissions -->
      <div class="mb-4" v-if="$store.state.submissions.length > 1">
        <select v-model="selectedSubmissionId" class="form-select mb-2">
          <option value="">Select a previous submission</option>
          <option v-for="sub in previousSubmissions" :key="sub.id" :value="sub.id">
            {{ $store.state.forms.find(form => form.id === sub.formId)?.name }} - {{ sub.label }}
          </option>
        </select>
        <button
          class="btn btn-primary btn-sm"
          @click="prefillFromOldSubmission"
          :disabled="!selectedSubmissionId"
        >Prefill From Selected Submission</button>
      </div>
      <label>Submission Label<br><small>Create a label that will help you identify this submission. Ex: 'Vineyard A Cabernet Franc'</small></label>
      <input
        v-model="submission.label"
        type="text"
        id="submissionLabel"
        class="form-control mt-2 mb-4"
        placeholder="Enter label here..."
        required
      />
      <hr>
      <div v-html="form.description"></div>
    </div>
  </div>
</template>

<script>
import ActionPlansInterface from './ActionPlansInterface.vue'
import ActionPlansReport from './ActionPlansReport.vue'
import CommentsInterface from './CommentsInterface.vue'
import fb from '../../firebaseConfig'
import FormStats from './FormStats.vue'
import RubricField from './field_types/RubricField.vue'
import BlocksAttachmentField from './field_types/BlocksAttachmentField.vue'

export default {
  data () {
    return {
      submitting: false,
      finalPageMode: 'stats', // vs 'actionPlans'
      selectedSubmissionId: '',
    }
  },
  computed: {
    adminMode: {
      get () {
        return this.$store.state.adminMode
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'adminMode', value: val })
      }
    },
    form: {
      get () {
        return this.$store.state.form
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'form', value: val })
      }
    },
    submission: {
      get () {
        return this.$store.state.submission
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'submission', value: val })
      }
    },
    group: {
      get () {
        return this.$store.state.group
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'group', value: val })
      }
    },
    previousSubmissions() {
      return this.$store.state.submissions
        .filter(sub => sub.id !== this.submission.id && sub.formId === this.form.id)
        .sort((a, b) => b.createdAt - a.createdAt)
        .slice(0, 5);
    }
  },
  components: {
    ActionPlansInterface,
    ActionPlansReport,
    CommentsInterface,
    FormStats,
    RubricField,
    BlocksAttachmentField
  },
  methods: {
    async updateStatus (status, backToList) {
      this.submitting = true

      // Add a Revision
      // SAVE REVISION FROM CURRENT DATA (REFETCH FIRST)
      const submissionDocument = await fb.submissionsCollection.doc(this.$store.state.submission.id).get()
      const submissionDoc = submissionDocument.data()
      submissionDoc.id = submissionDocument.id
      await fb.submissionsCollection.doc(this.$store.state.submission.id).collection('revisions').add(submissionDoc)

      this.submission.status = status
      fb.submissionsCollection.doc(this.submission.id).update(this.submission).then(() => {
        this.submitting = false
        if (backToList) {
          this.$store.commit('setAlert', {
            active: true,
            level: 'alert-success',
            message: 'Successfully updated ' + this.form.name,
            timeout: 5000
          })
          this.group.id = 'submitted'
          this.$store.dispatch('fetchAll')
          this.$store.commit('setStateProperty', { property: 'mode', value: 'grid' })
        }
      }).catch(err => {
        this.$store.commit('setAlert', {
          active: true,
          level: 'alert-danger',
          message: 'There was an issue submitting. Please try again.',
          timeout: 5000
        })
        this.submitting = false
      })
    },
    prefillFromOldSubmission() {
      if (!this.selectedSubmissionId) return;

      const oldSubmission = this.$store.state.submissions.find(sub => sub.id === this.selectedSubmissionId);
      if (!oldSubmission) {
        this.$store.commit('setAlert', {
          active: true,
          level: 'alert-danger',
          message: 'Selected submission not found',
          timeout: 5000
        });
        return;
      }

      // Copy answers from old submission to the current submission
      this.submission.answers = { ...oldSubmission.answers };

      this.$store.commit('setAlert', {
        active: true,
        level: 'alert-success',
        message: 'Successfully prefilled from previous submission',
        timeout: 5000
      });

      this.selectedSubmissionId = ''; // Reset selection
    }
  }
}
</script>

<style scoped>
  #groupInterface {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    color: #333;
  }

  .field-item {
    background-color: #eee;
    border-radius: 4px;
  }
</style>
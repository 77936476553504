<template>
  <div id="blocksAttachmentField">
    <h4>{{ fieldData.name }}<span v-if="fieldData.required" class="text-danger">*</span></h4>
    <p v-html="fieldData.description"></p>
    
    <div v-if="geoJsonFeatures && geoJsonFeatures.length" class="container">
      <div class="row">
        <div v-for="(feature, index) in geoJsonFeatures" :key="index" class="col-6">
          <label class="block-item p-2">
            <div v-if="feature.loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <img v-else :src="feature.thumbnail" alt="Block Thumbnail" class="block-thumbnail" />
            <div class="block-info">
              <h5>{{ feature.properties.name }}</h5>
              <input type="checkbox" :value="feature.properties.blockId" v-model="selectedBlocks" class="block-checkbox">
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import maplibregl from 'maplibre-gl';
import * as turf from '@turf/turf';

export default {
  props: ['fieldData', 'modelValue', 'answer'],
  data() {
    return {
      geoJsonFeatures: [],
      selectedBlocks: this.answer || [] // Initialize with the current answer
    };
  },
  watch: {
    selectedBlocks(newSelectedBlocks) {
      this.$emit('update:modelValue', newSelectedBlocks); // Emit the selected blocks to the parent
    }
  },
  created() {
    const storage = getStorage();
    const filePath = this.$store.state.farm.blocksGeoJsonPath;

    if (filePath) {
      const fileRef = ref(storage, filePath);

      getDownloadURL(fileRef)
        .then((url) => {
          return fetch(url);
        })
        .then((response) => response.json())
        .then((data) => {
          if (data && data.features) {
            this.geoJsonFeatures = data.features.map((feature, index) => ({
              ...feature,
              thumbnailId: `map-${index}`,
              thumbnail: null, // Placeholder for the thumbnail URL
              loading: true // Initialize loading state
            }));
            this.$nextTick(() => this.generateMapThumbnails(0)); // Start generating thumbnails sequentially
          }
        })
        .catch((error) => {
          console.error("Error fetching geojson file:", error);
        });
    } else {
      console.error("File path is not defined.");
    }
  },
  methods: {
    generateMapThumbnails(index) {
      if (index >= this.geoJsonFeatures.length) return; // Exit when all features are processed

      const feature = this.geoJsonFeatures[index];
      const map = new maplibregl.Map({
        container: document.createElement('div'), // Temporary container
        style: 'https://orbit.ist/trailhub.json',
        center: this.getFeatureCenter(feature),
        zoom: 14, // Initial zoom level, will be adjusted
        interactive: false // Disable user interactions
      });

      map.on('load', () => {
        map.addSource('polygon', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [feature]
          }
        });

        map.addLayer({
          id: 'polygon-fill',
          type: 'fill',
          source: 'polygon',
          layout: {},
          paint: {
            'fill-color': 'orange',
            'fill-opacity': 1
          }
        });

        map.addLayer({
          id: 'polygon-outline',
          type: 'line',
          source: 'polygon',
          layout: {},
          paint: {
            'line-color': '#000000',
            'line-width': 2
          }
        });

        // Fit the map to the bounding box of the feature
        const bbox = turf.bbox(feature); // Use Turf.js to calculate the bounding box
        map.fitBounds(bbox, { padding: 20, maxZoom: 16 }); // Adjust maxZoom as needed

        map.once('idle', () => {
          const canvas = map.getCanvas();
          const thumbnailUrl = canvas.toDataURL('image/png');
          this.geoJsonFeatures[index].thumbnail = thumbnailUrl; // Save the thumbnail URL
          this.geoJsonFeatures[index].loading = false; // Hide spinner once loaded
          map.remove(); // Clean up the map instance to free WebGL context

          // Process the next map after a short delay
          setTimeout(() => {
            this.generateMapThumbnails(index + 1);
          }, 10); // Adjust delay if necessary
        });
      });
    },
    getFeatureCenter(feature) {
      const coordinates = feature.geometry.coordinates[0];
      let lngSum = 0;
      let latSum = 0;

      coordinates.forEach(coord => {
        lngSum += coord[0];
        latSum += coord[1];
      });

      return [lngSum / coordinates.length, latSum / coordinates.length];
    }
  }
}
</script>

<style scoped>
  .block-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border: solid 1px #333;
    border-radius: 4px;
    cursor: pointer; /* Make the entire block clickable */
  }
  .block-thumbnail {
    margin-right: 10px;
    width: 300px;
    height: 300px;
    object-fit: cover;
  }
  .block-info {
    flex-grow: 1;
  }
  .block-checkbox {
    width: 20px; /* Increase the checkbox size */
    height: 20px; /* Increase the checkbox size */
    cursor: pointer; /* Ensure the cursor changes to pointer on hover */
  }
  .spinner-border {
    width: 3rem;
    height: 3rem;
    margin-right: 10px;
  }
</style>
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AdminHome from '../components/admin/AdminHome.vue'
import HomePage from '../components/HomePage.vue'
import LoginPage from '../components/admin/AdminLogin.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/:farmId',
    name: 'Home Page',
    component: HomePage,
  },
  {
    path: '/admin',
    name: 'AdminHome',
    component: AdminHome,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

<template>
  <!-- FORMS TABLE -->
  <div id="submissionsList" class="mt-2 container">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Farm</th>
          <th scope="col">User</th>
          <th scope="col">Status</th>
          <th scope="col" class="d-flex justify-content-end">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="submission in $store.state.submissions" :key="submission.id">
          <td>{{ submission.farm.name }}</td>
          <td>{{ submission.userName }}</td>
          <td
            :class="[(submission.status === 'draft') ? 'text-warning' : 'text-success']"
          >{{ submission.status }}</td>
          <td class="d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              @click="launchSubmission(submission)"
            ><i class="fas fa-edit"></i> View</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  methods: {
    launchSubmission (submission) {
      this.$store.commit('setStateProperty', { property: 'submission', value: submission })
      this.$store.commit('setStateProperty', { property: 'adminMode', value: 'form' })
    }
  }
}
</script>
<template>
  <div id="adminSubmissions">
    <div class="container">
      <h2 class="text-center">{{ $store.state.form.name }} Submissions</h2>
      <div class="d-flex justify-content-center mb-2">
        <div class="btn-group btn-group-sm" role="group" aria-label="Basic outlined example">
          <button
            type="button"
            class="btn btn-primary"
            :class="{ 'btn-secondary': $store.state.adminSubmissionsMode === 'list' }"
            @click="$store.commit('setStateProperty', { property: 'adminSubmissionsMode', value: 'list' })"
          >List</button>
          <button
            type="button"
            class="btn btn-primary"
            :class="{ 'btn-secondary': $store.state.adminSubmissionsMode === 'map' }"
            @click="$store.commit('setStateProperty', { property: 'adminSubmissionsMode', value: 'map' })"
          >Map</button>
          <button
            type="button"
            class="btn btn-primary"
            :class="{ 'btn-secondary': $store.state.adminSubmissionsMode === 'reports' }"
            @click="$store.commit('setStateProperty', { property: 'adminSubmissionsMode', value: 'reports' })"
          >Reports</button>
        </div>
      </div>
      <!-- FORMS TABLE -->
      <SubmissionsList v-if="$store.state.adminSubmissionsMode === 'list'" />
      <SubmissionsMap v-if="$store.state.adminSubmissionsMode === 'map'" />
      <SubmissionsReports v-if="$store.state.adminSubmissionsMode === 'reports'" />
    </div>
  </div>
</template>

<script>
import SubmissionsList from './submissions_elements/SubmissionsList.vue'
import SubmissionsMap from './submissions_elements/SubmissionsMap.vue'
import SubmissionsReports from './submissions_elements/SubmissionsReports.vue'

export default {
  components: {
    SubmissionsList,
    SubmissionsMap,
    SubmissionsReports
  }
}
</script>

<style scoped>
#adminSubmissions .container {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  color: #333;
}
</style>
<template>
  <div id="spinnerElement">
    <i class="fas fa-spinner-third fa-spin"></i>
  </div>
</template>

<style scoped>
#spinnerElement {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 9;
}

i {
  font-size: 50px;
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
}
</style>
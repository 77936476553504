<template>
  <div id="home">
    <RequestPassword v-if="$store.state.requestPassword" />
    <SpinnerElement v-if="$store.state.mode === 'loading'" />
    <FormsGrid v-if="$store.state.mode === 'grid'" />
    <FormInterface v-if="$store.state.mode === 'form'" />
    <HelpButton v-if="$store.state.mode === 'grid'" />
  </div>
</template>

<script>
import RequestPassword from './RequestPassword.vue'
import fb from '../firebaseConfig'
import FormsGrid from './front_end/FormsGrid.vue'
import FormInterface from './front_end/FormInterface.vue'
import SpinnerElement from './SpinnerElement.vue'
import HelpButton from './HelpButton.vue'

export default {
  created () {
    // Get the user email from the url
    let params = new URLSearchParams(window.location.search)
    let userEmail = params.get('email')
    fb.auth.onAuthStateChanged(user => {
      if (user && userEmail === user.email) {
        this.$store.dispatch('initApp')
      } else {
        if (user) {
          fb.auth.signOut().then(() => {
            this.$store.commit('setStateProperty', { property: 'requestPassword', value: true })
          }).catch(err => {
            console.log(err)
          })
        } else {
          console.log('No user logged in, request authentication')
          this.$store.commit('setStateProperty', { property: 'requestPassword', value: true })
        }
      }
    })
  },
  components: {
    RequestPassword,
    FormsGrid,
    FormInterface,
    SpinnerElement,
    HelpButton
  }

}
</script>

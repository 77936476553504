<template>
  <div id="requestPassword" class="pt-5">

    <!-- SPINNER -->
    <transition name="fade">
      <div id="map-spinner" v-if="performingRequest">
        <RingLoader
          :color="'#61539b'"
        ></RingLoader>
      </div><!-- /#map-spinner -->
    </transition>

    <div class="login-form">
      <img src="../assets/ev_logo.png" class="logo">
      <p>Please confirm your myEV Password.</p>

      <div class="signup-form">
        <!-- Login Form -->
        <form @submit.prevent>
          <div class="form-group">
            <label for="password1">Password</label>
            <input v-model.trim="loginForm.password" type="password" placeholder="******" id="password1" class="form-control" />
          </div><!-- /.form-group -->
          <button @click="login" class="btn btn-primary mt-2">Log In</button>
        </form>

        <!-- Error Message -->
        <transition name="fade">
          <div v-if="errorMsg !== ''" class="error-msg">
            <p>{{ errorMsg }}</p>
          </div><!-- error-msg -->
        </transition>

      </div><!-- /.signup-form -->
    </div><!-- /.container -->
  </div><!-- /#login -->
</template>

<script>
import fb from '../firebaseConfig.ts'
import RingLoader from 'vue-spinner/src/GridLoader'

export default {
  components: { RingLoader },
  data () {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      performingRequest: false,
      spinnerColor: '#61539b',
      spinnerSize: '50px',
      errorMsg: ''
    }
  },
  created () {
    let params = new URLSearchParams(window.location.search)
    this.loginForm.email = params.get('email')
  },
  methods: {
    toggleForm () {
      this.errorMsg = ''
      this.showLoginForm = !this.showLoginForm
    },
    togglePasswordReset () {
      if (this.showForgotPassword) {
        this.showLoginForm = true
        this.showForgotPassword = false
        this.passwordResetSuccess = false
      } else {
        this.showLoginForm = false
        this.showForgotPassword = true
      }
    },
    login () {
      this.performingRequest = true
      fb.auth.signInWithEmailAndPassword(this.loginForm.email, this.loginForm.password).then(user => {
        this.$store.commit('setCurrentUser', user.user)
        this.$store.dispatch('fetchUserProfile')
        this.performingRequest = false
        // this.$router.push('/admin')
        this.$store.commit('setStateProperty', { property: 'requestPassword', value: false })
        this.$store.dispatch('initApp')
      }).catch(err => {
        console.log(err)
        this.performingRequest = false
        this.errorMsg = err.message
      })
    },
    signup () {
      this.performingRequest = true
      fb.auth.createUserWithEmailAndPassword(this.signupForm.email, this.signupForm.password).then(user => {
        this.$store.commit('setCurrentUser', user.user)
        // create user obj
        fb.usersCollection.doc(user.user.uid).set({
          createdOn: new Date(),
          email: this.signupForm.email,
          emailNotifications: true,
          measurementSystem: 'Imperial'
        }).then(() => {
          this.$store.dispatch('fetchUserProfile')
          this.performingRequest = false
          this.$router.push('/admin')
        }).catch(err => {
          console.log(err)
          this.performingRequest = false
          this.errorMsg = err.message
        })
      }).catch(err => {
        console.log(err)
        this.performingRequest = false
        this.errorMsg = err.message
      })
    },
    resetPassword () {
      this.performingRequest = true
      fb.auth.sendPasswordResetEmail(this.passwordForm.email).then(() => {
        this.performingRequest = false
        this.passwordResetSuccess = true
        this.passwordForm.email = ''
      }).catch(err => {
        console.log(err)
        this.performingRequest = false
        this.errorMsg = err.message
      })
    }
  }
}
</script>

<style scoped>
#map-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: rgba(255,255,255,0.5);
}

.v-spinner {
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50vh - 50px);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.photo-credit {
  position: absolute;
  bottom: 0;
  right: 10px;
  color: white;
}
.photo-credit a {
  color: #eee;
}
</style>

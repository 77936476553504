<template>
  <!-- BASIC DETAILS -->
  <h3>Basic Details</h3>
  <div class="form-group">
    <label for="formName">Form Name</label>
    <input v-model="tempForm.name" type="text" id="formName" class="form-control" required />
  </div>
  <div class="form-group">
    <label>Description</label>
    <WysiwygEditor v-model="tempForm.description" />
  </div>
  <div class="form-group">
    <label>
      <input type="checkbox" v-model="tempForm.published" /> Form Published
    </label>
  </div>
  <hr>
</template>

<script>
import WysiwygEditor from '../../form_elements/WysiwygEditor.vue'

export default {
  components: {
    WysiwygEditor
  },
  computed: {
    tempForm: {
      get () {
        return this.$store.state.tempForm
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'tempForm', value: value })
      }
    },
  },
}
</script>
export default {
  // eslint-disable-next-line
  setAlert (state: any, value: any) {
    state.alert = value
    if (value.timeout > 0) {
      setTimeout(function () {
        state.alert.active = false
      }, value.timeout)
    }
  },
  // eslint-disable-next-line
  setCurrentUser(state: any, val: any) {
    state.currentUser = val;
  },
  // eslint-disable-next-line
  setUserProfile(state: any, val: any) {
    state.userProfile = val;
  },
  // eslint-disable-next-line
  setStateProperty (state: any, options: any) {
    state[options.property] = options.value
  },
};

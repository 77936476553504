<template>
  <a
    class="btn btn-success btn-lg"
    id="helpButton"
    href="https://orbitist.atlassian.net/wiki/spaces/EV/pages/225607682/NYWGF+Plugin+User+Manual"
    target="_blank"
  ><i class="fas fa-question-circle"></i> Help</a>
</template>

<style scoped>
  #helpButton {
    position: fixed;
    bottom: 30px;
    right: 30px;
    border-radius: 100px;
  }
</style>
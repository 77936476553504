<template>
  <div id="adminHome">
    <AdminNavbar v-if="adminMode !== 'form'" />
    <BreadcrumbInterface v-if="adminMode !== 'form'" />
    <AdminForms v-if="adminMode === 'forms'" />
    <AdminFarms v-if="adminMode === 'farms'" />
    <AdminCreateForm v-if="modalCreateForm === true" />
    <AdminSubmissions v-if="adminMode === 'submissions'" />
    <FormInterface v-if="adminMode === 'form'" />
  </div>
</template>

<script>
import AdminCreateForm from './AdminCreateForm.vue'
import AdminFarms from './AdminFarms.vue'
import AdminForms from './AdminForms.vue'
import AdminNavbar from './AdminNavbar.vue'
import AdminSubmissions from './AdminSubmissions.vue'
import BreadcrumbInterface from '../BreadcrumbInterface.vue'
import FormInterface from '../front_end/FormInterface.vue'

export default {
  created () {
    // GIVE ADMIN MODE A VALUE SO THE SYSTEM KNOWS WE ARE ADMINISTERING THINGS
    this.$store.commit('setStateProperty', { property: 'adminMode', value: 'forms' })
    this.$store.dispatch('fetchForms')
    this.$store.dispatch('fetchPluginData')
  },
  components: {
    AdminCreateForm,
    AdminFarms,
    AdminForms,
    AdminNavbar,
    AdminSubmissions,
    BreadcrumbInterface,
    FormInterface
  },
  computed: {
    adminMode: {
      get () {
        return this.$store.state.adminMode
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'adminMode', value: val })
      }
    },
    modalCreateForm: {
      get () {
        return this.$store.state.modalCreateForm
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'modalCreateForm', value: val })
      }
    }
  }
}
</script>

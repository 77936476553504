<template>
  <div id="adminUsers">
    <div class="container">
      <!-- FARMS TABLE -->
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Farm</th>
            <th class="d-flex justify-content-end" scope="col">Access Granted</th>
          </tr>
        </thead>
        <tbody v-if="$store.state.farms">
          <tr v-for="farm in $store.state.farms" :key="farm.id">
            <td>{{ farm.name }}</td>
            <td class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                @click="toggleApprove(farm, true)"
                v-if="!$store.state.plugin.farms || !$store.state.plugin.farms.includes(farm.id)"
              >Approve</button>
              <button
                type="button"
                class="btn btn-success btn-sm ms-1"
                @click="toggleApprove(farm, false)"
                v-if="$store.state.plugin.farms && $store.state.plugin.farms.includes(farm.id)"
              ><i class="fas fa-check"></i> Approved</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import fb from '../../firebaseConfig'

export default {
  created () {
    this.$store.dispatch('fetchFarms')
  },
  computed: {
    plugin: {
      get () {
        return this.$store.state.plugin
      },
      set (val) {
        this.$store.commit('setStateProperty', { property: 'plugin', value: val})
      }
    }
  },
  methods: {
    savePlugin () {
      fb.db.collection('plugins').doc('nywgf').update(this.plugin)
    },
    toggleApprove (farm, approved) {
      if (!this.plugin.farms) {
        this.plugin.farms = []
      }
      if (approved) {
        this.plugin.farms.push(farm.id)
      } else {
        this.plugin.farms = this.plugin.farms.filter(item => item !== farm.id)
      }
      this.savePlugin()
    }
  }
}
</script>

<style scoped>
  .container {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    color: #333;
  }
</style>

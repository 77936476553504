import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/auth'

const config = {
  apiKey: 'AIzaSyD_RfZOjyIrT878HpQ_xN5vfu2tEc-HGjU',
  authDomain: 'efficient-vineyard.firebaseapp.com',
  databaseURL: 'https://efficient-vineyard.firebaseio.com',
  projectId: 'efficient-vineyard',
  storageBucket: 'efficient-vineyard.appspot.com',
  messagingSenderId: '231171307256',
  appId: '1:231171307256:web:da1ed130656409f6faed0d',
};

firebase.initializeApp(config)

// firebase utils
const db = firebase.firestore()
const storage = firebase.storage()
const auth = firebase.auth()

// firebase collections
const usersCollection = db.collection('users')
const farmsCollection = db.collection('farms')
const formsCollection = db.collection('plugins').doc('nywgf').collection('forms')
const formsTrashCollection = db.collection('plugins').doc('nywgf').collection('formsTrash')
const pluginData = db.collection('plugins').doc('nywgf')
const submissionsCollection = db.collection('plugins').doc('nywgf').collection('submissions')

export default {
  auth,
  db,
  farmsCollection,
  formsCollection,
  formsTrashCollection,
  pluginData,
  storage,
  submissionsCollection,
  usersCollection
}

<template>
  <div class="mt-2">
    <div v-if="editor">
      <button @click="editor.chain().focus().toggleBold().run()" class="btn btn-secondary btn-sm me-1" :class="{ 'btn-primary': editor.isActive('bold') }">
        <i class="fas fa-bold"></i>
      </button>
      <button @click="editor.chain().focus().toggleItalic().run()" class="btn btn-secondary btn-sm me-1" :class="{ 'btn-primary': editor.isActive('italic') }">
        <i class="fas fa-italic"></i>
      </button>
      <button @click="editor.chain().focus().setParagraph().run()" class="btn btn-secondary btn-sm me-1" :class="{ 'btn-primary': editor.isActive('paragraph') }">
        <i class="fas fa-text"></i>
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" class="btn btn-secondary btn-sm me-1" :class="{ 'btn-primary': editor.isActive('heading', { level: 1 }) }">
        h1
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" class="btn btn-secondary btn-sm me-1" :class="{ 'btn-primary': editor.isActive('heading', { level: 2 }) }">
        h2
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" class="btn btn-secondary btn-sm me-1" :class="{ 'btn-primary': editor.isActive('heading', { level: 3 }) }">
        h3
      </button>
      <button @click="editor.chain().focus().toggleBulletList().run()" class="btn btn-secondary btn-sm me-1" :class="{ 'btn-primary': editor.isActive('bulletList') }">
        <i class="fas fa-list"></i>
      </button>
      <button @click="editor.chain().focus().toggleOrderedList().run()" class="btn btn-secondary btn-sm me-1" :class="{ 'btn-primary': editor.isActive('orderedList') }">
        <i class="fas fa-list-ol"></i>
      </button>
      <button @click="editor.chain().focus().toggleBlockquote().run()" class="btn btn-secondary btn-sm me-1" :class="{ 'btn-primary': editor.isActive('blockquote') }">
        <i class="fas fa-quote-right"></i>
      </button>
      <button @click="editor.chain().focus().setHorizontalRule().run()" class="btn btn-secondary btn-sm">
        <i class="fas fa-horizontal-rule"></i>
      </button>
    </div>
    <EditorContent :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'

export default {
  props: ['modelValue'],
  emits: ['update:modelValue'],
  data () {
    return {
      editor: null
    }
  },
  watch: {
    'editor.state': {
      handler () {
        this.$emit('update:modelValue', this.editor.getHTML())
      },
      deep: true
    }
  },
  mounted () {
    this.editor = new Editor({
      extensions: [ StarterKit ],
      content: this.modelValue
    })
  },
  beforeUnmount () {
    this.editor.destroy()
  },
  components: {
    EditorContent
  }
}
</script>
